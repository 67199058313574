<template>
  <div class="about-container">
    <div style="text-align: center">
      <Avatar  :url="$store.state.setting.data.avatar"  />
      <p>刘帅洋</p>
      <p>男、00后</p>
      <p>是一名前端开发工程师</p>
      <p>是千万程序猿中的一只</p>
      <p>热爱编程</p>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar'
export default {
  components:{
    Avatar
  }
};
</script>

<style scoped>
.about-container {
  width: 100%;
  height: 100%;
  margin: 0px auto;
  overflow: hidden;
  position: relative;
  background: #eee;
  padding: 100px 0 0 0;
}
.about-content {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
}
img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.txt {
  font-size: 14px;
}
</style>